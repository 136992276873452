import { BackendMethod } from '@memberspot/shared/model/backend';

import { CreatePageDto, UpdatePageDto } from './dto/page.dto';

export const PageManageEndpoints = {
  GET_PAGE: (schoolId: string, pageId: string) => ({
    url: `/page/manage/${schoolId}/${pageId}`,
    method: BackendMethod.GET,
  }),
  GET_ALL_PAGES: (schoolId: string) => ({
    url: `/page/manage/${schoolId}`,
    method: BackendMethod.GET,
  }),
  CREATE_PAGE: (schoolId: string, data: CreatePageDto) => ({
    url: `/page/manage/${schoolId}`,
    method: BackendMethod.POST,
    data,
  }),
  UPDATE_PAGE: (schoolId: string, data: UpdatePageDto) => ({
    url: `/page/manage/${schoolId}`,
    method: BackendMethod.PATCH,
    data,
  }),
  DELETE_PAGE: (schoolId: string, pageId: string) => ({
    url: `/page/manage/${schoolId}/${pageId}`,
    method: BackendMethod.DELETE,
  }),
};

export const PageContentEndpoints = {
  GET_PAGE: (schoolId: string, pageId: string) => ({
    url: `/page/content/${schoolId}/${pageId}`,
    method: BackendMethod.GET,
  }),
};
