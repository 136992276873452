import { inject, Injectable } from '@angular/core';
import { HttpBackendService } from '@memberspot/frontend/shared/data-access/common';
import { NotiService } from '@memberspot/frontend/shared/util/noti';
import {
  CreatePageProperties,
  Page,
  PageManageEndpoints,
  UpdatePageProperties,
} from '@memberspot/shared/model/page';
import { RouterRepository } from '@ngneat/elf-ng-router-store';
import { first, switchMap, tap } from 'rxjs';

import { PagesStore } from './pages.store';

@Injectable({ providedIn: 'root' })
export class PagesService {
  store = inject(PagesStore);
  http = inject(HttpBackendService);
  routerRepository = inject(RouterRepository);
  noti = inject(NotiService);

  sync(schoolId: string) {
    this.store.reset();
    this.store.setLoading(true);

    return this.http
      .generic<Page<Date>[]>(PageManageEndpoints.GET_ALL_PAGES(schoolId))
      .pipe(
        tap((pages) => {
          this.store.set(pages);
          this.store.setLoading(false);
        }),
      );
  }

  resetStore() {
    return this.store.reset();
  }

  upsertPageInStore(page: Page<Date>) {
    this.store.upsert(page.id, page);
  }

  addPage(properties: CreatePageProperties) {
    return this.routerRepository.selectParams<string>('schoolId').pipe(
      first(),
      switchMap((schoolId) =>
        this.http
          .generic<Page<Date>>(
            PageManageEndpoints.CREATE_PAGE(schoolId, properties),
          )
          .pipe(
            tap((page) => {
              this.store.upsert(page.id, page);
            }),
          ),
      ),
    );
  }

  updatePage(properties: UpdatePageProperties) {
    return this.routerRepository.selectParams<string>('schoolId').pipe(
      first(),
      switchMap((schoolId) =>
        this.http
          .generic<Page<Date>>(
            PageManageEndpoints.UPDATE_PAGE(schoolId, properties),
          )
          .pipe(
            tap((page) => {
              this.store.upsert(page.id, page);
            }),
          ),
      ),
    );
  }

  deletePage(pageId: string) {
    return this.routerRepository.selectParams<string>('schoolId').pipe(
      first(),
      switchMap((schoolId) =>
        this.http
          .generic(PageManageEndpoints.DELETE_PAGE(schoolId, pageId))
          .pipe(
            tap(() => {
              this.store.remove(pageId);
            }),
          ),
      ),
    );
  }
}
